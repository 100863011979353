import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useNavigate } from 'react-router-dom';
import { loadError, loadSuccess, APIHOST,  isLoggedIn, getCandy, showLoading  } from '../../utils/gaia';
import Axios from 'axios';
import MemberModal from '../Components/MemberModal';
const ScanQr = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isPaused, setIspaused] = useState(false);
    const [showMember, setShowMember] = useState(false);
    const [canScan, setCanScan] = useState(true);
    const [member, setMember] = useState([])
    const [conversion, setConversion] = useState("")

    useEffect(()=>{
        if(!isLoggedIn()){navigate(`/`)}
    },[])

    const scanResult = (result) =>{
        console.log(result)
        if (result.length > 0 && !isPaused) {
            setIspaused(true)
            checkQR(result[0]?.rawValue);
        }
    }

    const hideMember = () =>{
        window.location.reload();
    }

    const redeem = () =>{
        setLoading(true)
        showLoading("Redeeming Vouchers, please wait...")
        Axios.post(`${APIHOST}${member.type == "AI" ? "nonMember/redeem" : "kiosk/redeem"}`, {
            cardno : member.cardno,
            session: getCandy()
        }).then(function(res){
            setTimeout(()=>setIspaused(false), 2000);
            loadSuccess(res.msg)
            //setShowMember(false)
            let m = member;
            m.vouchers = 0;
            m.points = 0;
            setMember(m);
            //setMember([])
        }).catch(function(er){
          setLoading(false)
          setTimeout(()=>setIspaused(false), 2000);
          if(typeof(er.response) !== "undefined"){
            if(er.response.status == 404 || er.response.status == 500){
              loadError(er.response.data.error ?? er.response.data.message)
            }else{
              loadError("A network error occured.")
            }
          }else{
            loadError(er.message)
          }
          
        })
    }

    const checkQR = (data) =>{
        if(data == ""){
          loadError("Please scan the qr code")
          setIspaused(false)
          return;
        }
        setLoading(true)
        setCanScan(false)
        showLoading("Verifying QR code, please wait...")
        Axios.post(`${APIHOST}concierge/verifyQr`, {
            qr : data,
            session: getCandy()
        }).then(function(res){
            let {data} = res;
            setLoading(false)
            loadSuccess(data.msg)
            setMember(data.member)
            setShowMember(true)
            setConversion(data.conversion)
        }).catch(function(er){
          setLoading(false)
          setTimeout(()=>setIspaused(false), 2000);
          if(typeof(er.response) !== "undefined"){
            if(er.response.status == 404 || er.response.status == 500){
              loadError(er.response.data.error ?? er.response.data.message)
            }else{
              loadError("A network error occured.")
            }
          }else{
            loadError(er.message)
          }
          
        })
    }

 
  return (
    <>
        <main className="common-bg">
            <section className="bg-img">
                <div className="nav-home">
                    <a href="/home"><img src={Images.homebtn} alt="" />   </a>
                </div>
                <div className="common-center">
                    <div className="container">
                        <div>
                            <div className="scanner-code-box">
                                <div className="qr-scaner-code ">
                                    <h2>CHECK / CONVERT POINTS</h2>
                                    <div className="">
                                        <Scanner scanDelay={3000} allowMultiple={true} paused={isPaused} onScan={(result) => scanResult(result)} constraints={{ facingMode: 'environment' }} classNames={{container : "scanner-scan"}}  />
                                    </div>
                                </div>
                                <h5>SCAN OR COOE</h5>
                            </div>    
                        </div>
                    </div>
                </div>
        </section>
    </main>
    {showMember ? <MemberModal open={showMember} member={member} close={hideMember} redeem={redeem} conversion={conversion}  /> : ""}
    </>
  );
}

export default ScanQr;
